import React, { FC, ReactNode } from 'react';
import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface SpacingLayoutProps extends BoxProps {
  className?: string;
  children: ReactNode;
  paddingXDesktop?: number;
  paddingYDesktop?: number;
  paddingXMobile?: number;
  paddingYMobile?: number;
}

type StyleProps = Partial<SpacingLayoutProps>;

const useStyles = makeStyles((theme: Theme) => ({
  paddingBox: {
    padding: ({ paddingXMobile, paddingYMobile }: StyleProps) => theme.spacing(paddingYMobile || 14, paddingXMobile || 0),
    [theme.breakpoints.up('md')]: {
      padding: ({ paddingXDesktop, paddingYDesktop }: StyleProps) => theme.spacing(paddingYDesktop || 20, paddingXDesktop || 0),
    },
  },
}));

const SpacingLayout: FC<SpacingLayoutProps> = ({
  children,
  className,
  paddingXMobile,
  paddingYMobile,
  paddingXDesktop,
  paddingYDesktop,
  ...props
}) => {
  const classes = useStyles({ paddingXMobile, paddingYMobile, paddingXDesktop, paddingYDesktop });
  return (
    <Box className={clsx(className, classes.paddingBox)} {...props}>
      {children}
    </Box>
  );
};

export default SpacingLayout;

import React, {FC, useEffect, useState} from 'react';
import { Theme, useMediaQuery, useScrollTrigger } from '@material-ui/core';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

interface NavbarProps {
  showNavbarBg: boolean;
}

const Navbar: FC<NavbarProps> = ({ showNavbarBg }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const scrollDown = useScrollTrigger();

  const [isTop, setIsTop] = useState<boolean>(true);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 100 ? setIsTop(false) : setIsTop(true);
    });

    return () => {
      window.removeEventListener('scroll', () => {
        setIsTop(true);
      });
    };
  }, []);

  return (
    <>
      {isDesktop ? (
        <DesktopMenu isTop={!showNavbarBg && isTop} scrollDown={scrollDown} />
      ) : (
        <MobileMenu isTop={!showNavbarBg && isTop} scrollDown={scrollDown} />
      )}
    </>
  );
};

export default Navbar;

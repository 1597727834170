import React, { FC, useState } from 'react';
import { MenuProps } from 'templates/layout/navbar/types';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import { BurgerDarkIcon, BurgerLightIcon, MobileLogoColored, MobileLogoWhite, MobileMenuLogoIcon } from 'svgs';
import { QUOTE_ROUTE, routes } from 'data/routes';
import { NextRouter, useRouter } from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    padding: theme.spacing(2, 4),
  },
  menuIcon: {
    width: 23,
    height: 23,
    position: 'relative',
  },
  list: {
    marginTop: theme.spacing(12),
  },
  activeLink: {
    fontWeight: 900,
    color: theme.palette.primary.main,
    '&::before': {
      content: '""',
      height: theme.spacing(5),
      width: 4,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: theme.spacing(-7),
    },
  },
  listItem: {
    padding: theme.spacing(2, 0),
  },
  hideNavbar: {
    animation: '$hideNavbar 300ms ease-out forwards',
  },
  showNavbar: {
    animation: '$showNavbar 300ms ease-out forwards',
  },
  '@keyframes showNavbar': {
    from: {
      transform: 'translateY(-50px)',
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes hideNavbar': {
    from: {
      transform: 'translateY(0px)',
      opacity: 1,
    },
    to: {
      transform: 'translateY(-50px)',
      opacity: 0,
    },
  },
  menu: {
    height: '100%',
    '@media(max-height: 460px) and (max-width: 992px)': {
      height: 'auto',
    },
  },
  link: {
    margin: theme.spacing(10, 0, 0),
  },
}));

const StyledDrawer = withStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxWidth: '77%',
    padding: theme.spacing(4, 7),
    position: 'relative',
    overflowX: 'hidden',
  },
}))(Drawer);

const MobileMenu: FC<MenuProps> = ({ isTop, scrollDown }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { asPath }: NextRouter = useRouter();
  const classes = useStyles();

  const bgColor = isTop ? 'transparent' : 'secondary';
  const handleOpen = () => setShowMenu(true);
  const handleClose = () => setShowMenu(false);

  return (
    <AppBar className={clsx(scrollDown ? classes.hideNavbar : classes.showNavbar)} color={bgColor} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <>{isTop ? <MobileLogoWhite /> : <MobileLogoColored />}</>
          <IconButton onClick={handleOpen}>{isTop ? <BurgerLightIcon /> : <BurgerDarkIcon />}</IconButton>
        </Box>
      </Toolbar>
      <StyledDrawer anchor="left" open={showMenu} onClose={handleClose}>
        <Box display="flex" justifyContent="space-between" flexDirection="column" className={classes.menu}>
          <Box>
            <MobileMenuLogoIcon />
            <List className={classes.list}>
              {routes.map(({ path, label }, index) => (
                <ListItem key={index} className={classes.listItem} onClick={handleClose}>
                  <Link href={path}>
                    <a>
                      <Typography className={clsx(path === asPath && classes.activeLink)}>{label}</Typography>
                    </a>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
          <Link href={QUOTE_ROUTE}>
            <a className={classes.link}>
              <Button size="small" fullWidth color="primary" variant="contained" onClick={handleClose}>
                Get a Quote
              </Button>
            </a>
          </Link>
        </Box>
      </StyledDrawer>
    </AppBar>
  );
};

export default MobileMenu;

// Email default parameters
export const ENV_EMAIL_HOST = process.env.NEXT_PUBLIC_EMAIL_HOST;
export const ENV_EMAIL_PORT = Number(process.env.NEXT_PUBLIC_EMAIL_PORT);
export const ENV_EMAIL_SECURE = process.env.NEXT_PUBLIC_EMAIL_SECURE !== 'false';

// Contact us
export const ENV_EMAIL_USERNAME = process.env.NEXT_PUBLIC_EMAIL_USERNAME;
export const ENV_EMAIL_PASSWORD = process.env.NEXT_PUBLIC_EMAIL_PASSWORD;

// Sentry
export const ENV_SENTRY_COMMIT_SHA = process.env.NEXT_PUBLIC_SENTRY_COMMIT_SHA;
export const ENV_SENTRY_APP_STAGE = process.env.NEXT_PUBLIC_SENTRY_APP_STAGE;
export const ENV_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const ENV_SENTRY_BUILD_TIME = process.env.NEXT_PUBLIC_SENTRY_BUILD_TIME;

// Other
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'https://silverlogistics.net';
export const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY;
export const ENV_IS_PRODUCTION = process.env.NODE_ENV === 'production';

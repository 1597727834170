import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import React, { FC, FormEvent } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  successButton: {
    backgroundColor: theme.palette.success.main,
    fontWeight: 400,
    width: '100%',
    transition: 'all 200ms',
    '&:hover': {
      transform: 'translate(0)',
      backgroundColor: theme.palette.success.main,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    fontWeight: 400,
    width: '100%',
    transition: 'all 200ms',
    '&:hover': {
      transform: 'translate(0)',
      backgroundColor: theme.palette.error.main,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
  },
}));

interface SubmitButtonProps extends ButtonProps {
  error?: boolean;
  success?: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = ({ children, error = false, success = false, ...props }) => {
  const classes = useStyles();
  const preventClick = (e: FormEvent) => {
    if (success) e.preventDefault();
  };
  return (
    <Button
      {...props}
      type="submit"
      color="primary"
      variant="contained"
      className={clsx(error && classes.errorButton, success && classes.successButton)}
      onClick={preventClick}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;

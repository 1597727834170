import React, { FC, ReactNode } from 'react';
import { Box, Button, Container, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import Link from 'next/link';
import clsx from 'clsx';
import { animated, useSpring } from 'react-spring';
import { animationText, animationTitle } from 'utils/animations/layout';

interface HeaderProps {
  image: string;
  title: ReactNode | string;
  text: ReactNode | string;
  link: string;
  buttonText: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  hero: {
    minHeight: 540,
    backgroundSize: 'cover !important',
    backgroundPositionX: '75% !important',
    background: ({ image }: Partial<HeaderProps>) =>
      `linear-gradient(0deg, rgba(0, 26, 85, 0.5), rgba(0, 26, 85, 0.5)), url(${image}) no-repeat`,
    [theme.breakpoints.up('md')]: {
      minHeight: 640,
    },
  },
  heroContent: {
    width: '100%',
    paddingTop: 130,
    [theme.breakpoints.up('sm')]: {
      paddingTop: '23%',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '18%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '13%',
    },
  },
  subtitle: {
    fontSize: 22,
    lineHeight: '23px',
    margin: theme.spacing(1, 0, 8),
  },
}));

const Hero: FC<HeaderProps> = ({ className, title, image, buttonText, text, link }) => {
  const classes = useStyles({ image });

  const fadeInTitle = useSpring(animationTitle);
  const fadeInText = useSpring(animationText(300));
  const fadeInButton = useSpring(animationText(450));

  return (
    <Box className={clsx(classes.hero, className)}>
      <Container maxWidth={false} fixed>
        <Grid container>
          <Hidden mdDown>
            <Grid item md={1} />
          </Hidden>
          <Box className={classes.heroContent}>
            <animated.div style={fadeInTitle}>
              <Typography variant="h1" component="h1" color="textSecondary">
                {title}
              </Typography>
            </animated.div>
            <animated.div style={fadeInText}>
              <Typography className={classes.subtitle} variant="subtitle1" color="textSecondary">
                {text}
              </Typography>
            </animated.div>
            <animated.div style={fadeInButton}>
              <Link href={link}>
                <a>
                  <Button variant="contained" color="primary">
                    {buttonText}
                  </Button>
                </a>
              </Link>
            </animated.div>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;

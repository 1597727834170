import React, { FC, useState } from 'react';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Box, fade, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CalendarInputIcon } from 'svgs';

type TNullableDate = Date | null;

interface DatePickerProps {
  onChange: (value: TNullableDate) => void;
  value: Date | null;
  label?: string;
  error?: boolean;
  hintMessage?: string;
  counter?: number;
  number?: boolean;
  required?: boolean;
  disabled?: boolean;
  min?: TNullableDate;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    position: 'absolute',
    color: theme.palette.secondary.light,
    top: theme.spacing(1.75),
    left: theme.spacing(3),
    zIndex: 1,
    transition: 'all 200ms ease-out',
  },
  inputActive: {
    top: theme.spacing(-5),
    left: 0,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  hintMessage: {
    fontSize: 14,
    position: 'absolute',
    right: 0,
    top: theme.spacing(-5),
    color: theme.palette.text.primary,
    animation: '$hintText 350ms ease-out forwards',
  },
  focused: {
    animation: '$focusInput 200ms ease-out forwards',
  },
  labelHovered: {
    backgroundColor: fade(theme.palette.common.black, 0.13),
  },
  '@keyframes focusInput': {
    from: {
      transform: `translateX(-15px)`,
    },
    to: {
      transform: 'translateX(0)',
    },
  },
  calendarIcon: {
    position: 'absolute',
    top: theme.spacing(1.75),
    right: theme.spacing(3),
    cursor: 'text',
  },
  iconHover: {
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
  '@keyframes hintText': {
    from: {
      transform: `translateY(15px)`,
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
}));

const DatePicker: FC<DatePickerProps> = ({ label, required, hintMessage, value, onChange, disabled = false, min }) => {
  const classes = useStyles();
  const [labelHover, setLabelHover] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [hoverInput, setHoverInput] = useState<boolean>(false);

  const handleDateChange = (date: Date | null) => onChange(date);

  const handleOpenCalendar = () => {
    if (!disabled) setOpenCalendar(true);
  };
  const handleCloseCalendar = () => setOpenCalendar(false);

  const isInputActive = !value && classes.focused;
  const placeholderActive = value && classes.inputActive;

  const isColored = hoverInput || labelHover;
  return (
    <Box position="relative">
      {label && (
        <Typography
          onClick={handleOpenCalendar}
          onMouseEnter={() => setLabelHover(true)}
          onMouseLeave={() => setLabelHover(false)}
          className={clsx(classes.label, placeholderActive && classes.inputActive)}
        >
          {label}{' '}
          {required && (
            <Typography color="primary" component="span">
              *
            </Typography>
          )}
        </Typography>
      )}
      {hintMessage && <Typography className={classes.hintMessage}>{hintMessage}</Typography>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiDatePicker
          disableToolbar
          animateYearScrolling
          variant="dialog"
          format="MM / dd / yyyy"
          margin="none"
          minDate={min}
          value={value}
          onChange={handleDateChange}
          onAccept={handleCloseCalendar}
          onClose={handleCloseCalendar}
          disabled={disabled}
          open={openCalendar}
          TextFieldComponent={(props) => (
            <>
              <TextField
                {...props}
                onMouseEnter={() => setHoverInput(true)}
                onMouseLeave={() => setHoverInput(false)}
                fullWidth
                variant="filled"
                InputProps={{ disableUnderline: true, className: clsx(labelHover && classes.labelHovered) }}
                inputProps={{
                  className: clsx(isInputActive),
                }}
                onClick={handleOpenCalendar}
              />
              <CalendarInputIcon
                onMouseEnter={() => setLabelHover(true)}
                onMouseLeave={() => setLabelHover(false)}
                onClick={handleOpenCalendar}
                className={clsx(classes.calendarIcon, isColored && !disabled && classes.iconHover)}
              />
            </>
          )}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default DatePicker;

import React, { FC } from 'react';
import { Box, Button, Container, Grid, Hidden, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import Link from 'next/link';
import { SpacingLayout } from 'components/atoms';
import { SEO } from 'templates/layout';
import { animated, useSpring } from 'react-spring';
import { animationText } from 'utils/animations/layout';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    paddingTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    minHeight: 'auto',
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
      paddingTop: 0,
    },
  },
  mainText: {
    fontSize: 144,
    lineHeight: 1,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 254,
      lineHeight: 1.4,
    },
  },
  hrLine: {
    margin: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    borderLeft: 0,
    borderRight: 0,
    width: 'calc(100% + 30px)',
    [theme.breakpoints.up(375)]: {
      width: 'calc(100% + 60px)',
    },
    [theme.breakpoints.up('md')]: {
      width: 'inherit',
      margin: theme.spacing(0, 6),
      borderTop: `351px solid ${theme.palette.primary.main}`,
      borderBottom: 0,
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      maxWidth: 'max-content',
    },
  },
  titleText: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.4,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
  },
  text: {
    lineHeight: 1.4,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
}));

interface ErrorPageProps {
  errorTitle: string;
  errorCode: number;
  errorText: string;
  seoTitle: string;
}

const Error: FC<ErrorPageProps> = ({ errorText, errorCode, seoTitle, errorTitle }) => {
  const classes = useStyles();
  const isMediumUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const fadeInDelay = (ms: number) => useSpring(animationText(ms));

  return (
    <SpacingLayout>
      <SEO title={seoTitle} description={''} />
      <Container className={classes.container} maxWidth={false} fixed>
        <Grid spacing={6} container justify="center">
          <Hidden mdDown>
            <Grid lg={2} item />
          </Hidden>

          <Grid lg={8} item>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection={isMediumUp ? 'row' : 'column'}>
              <Typography
                data-aos={isMediumUp ? 'fade-left' : 'fade-down'}
                data-aos-delay="400"
                className={classes.mainText}
                color="primary"
              >
                {errorCode}
              </Typography>
              <hr data-aos="zoom-in" data-aos-delay="500" className={classes.hrLine} />
              <Box>
                <animated.div style={fadeInDelay(600)}>
                  <Typography component="h1" className={classes.titleText} color="primary">
                    {errorTitle}
                  </Typography>
                </animated.div>
                <animated.div style={fadeInDelay(700)}>
                  <Typography variant="h3" component="h2" color="textPrimary" className={classes.text}>
                    {errorText}
                  </Typography>
                </animated.div>
                <Link href="/">
                  <a>
                    <animated.div style={fadeInDelay(800)}>
                      <Button color="primary" variant="contained">
                        Home
                      </Button>
                    </animated.div>
                  </a>
                </Link>
              </Box>
            </Box>
          </Grid>

          <Hidden mdDown>
            <Grid lg={2} item />
          </Hidden>
        </Grid>
      </Container>
    </SpacingLayout>
  );
};

export default Error;

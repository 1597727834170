import React, { ChangeEvent, FC } from 'react';
import { Box, fade, makeStyles, MenuItem, StandardTextFieldProps, TextField, Theme, Typography } from '@material-ui/core';
import { ArrowDownIcon } from 'svgs';
import clsx from 'clsx';

export type SelectData = {
  label: string;
  value: string | number;
};

interface SelectInputProps extends StandardTextFieldProps {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  selectData: SelectData[];
  fullWidth?: boolean;
  label?: string;
  hintText?: boolean;
  error?: boolean;
  disabled?: boolean;
  notSelected?: boolean;
  required?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    position: 'absolute',
    top: '-50%',
    fontSize: 14,
  },
  arrowIcon: {
    position: 'absolute',
    right: 15,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  menuList: {
    '& .MuiPaper-root ': {
      borderRadius: 0,
      boxShadow: `0px 5px 20px ${fade(theme.palette.common.black, 0.15)}`,
    },
  },
  hintMessage: {
    fontSize: 14,
    position: 'absolute',
    right: 0,
    top: theme.spacing(-5),
    color: theme.palette.text.primary,
  },
  hintError: {
    color: theme.palette.error.main,
    transition: 'all 200ms ease-in',
  },
  notSelected: {
    color: theme.palette.secondary.light,
  },
}));

const Select: FC<SelectInputProps> = ({
  selectData,
  disabled,
  onChange,
  value,
  label,
  error,
  hintText,
  notSelected = false,
  required = false,
  ...props
}) => {
  const classes = useStyles();
  const firstValue = selectData[0].value || '';
  return (
    <Box position="relative">
      {label && (
        <Typography className={clsx(classes.label, error && classes.errorColor)}>
          {label}{' '}
          {required && (
            <Typography color={error ? 'error' : 'primary'} className={clsx(error && classes.errorColor)} component="span">
              *
            </Typography>
          )}
        </Typography>
      )}
      {hintText && <Typography className={clsx(classes.hintMessage, error && classes.hintError)}>{hintText}</Typography>}
      <TextField
        {...props}
        select
        fullWidth
        disabled={disabled}
        SelectProps={{
          disableUnderline: true,
          IconComponent: () => <ArrowDownIcon className={classes.arrowIcon} />,
          MenuProps: {
            className: classes.menuList,
          },
        }}
        value={value || firstValue}
        onChange={onChange}
        variant="filled"
      >
        {notSelected && (
          <MenuItem className={classes.notSelected} value="Not Selected">
            Not Selected
          </MenuItem>
        )}
        {selectData.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default Select;

import { ChangeEvent } from 'react';

export const isNumberRegax = (e: ChangeEvent<HTMLInputElement>, onChange: (e: ChangeEvent) => void): void => {
  const re = /[0-9]+/g;
  if (e.target.value === '' || re.test(e.target.value)) {
    onChange(e);
    return;
  }

  onChange(e);
};

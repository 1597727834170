import React, { FC } from 'react';
import { Box, BoxProps, Theme, withStyles } from '@material-ui/core';

const StyledBox = withStyles((theme: Theme) => ({
  root: {
    transform: 'translateX(-15px)',
    width: '100%',
    maxWidth: theme.spacing(14),
    height: 4,
    background: theme.palette.primary.main,
  },
}))(Box);

const Separator: FC<BoxProps> = (props) => <StyledBox {...props} />;

export default Separator;

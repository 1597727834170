import React, { FC, useEffect, useRef, useState } from 'react';
import { AppBar, Box, Button, Container, Grid, makeStyles, Theme, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import { ColoredNavbarLogo, WhiteNavbarLogo } from 'svgs';
import { NextRouter, useRouter } from 'next/router';
import Link from 'next/link';
import { HOME_ROUTE, QUOTE_ROUTE, routes } from 'data/routes';
import clsx from 'clsx';
import { MenuProps } from 'templates/layout/navbar/types';

interface StyleProps extends Partial<MenuProps> {
  activeLinkParams: {
    width: number;
    bottom: number;
    left: number;
  };
}

interface NavLinkProps {
  path: string;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    padding: ({ scrollDown }: StyleProps) => (scrollDown ? theme.spacing(1, 0) : theme.spacing(4, 0)),
    transition: 'padding 300ms ease-out',
  },
  link: {
    color: ({ isTop }: StyleProps) => (isTop ? 'white' : 'black'),
    '&:hover': {
      textShadow: '0.3px 0.3px currentColor',
    },
  },
  activeLink: {
    textShadow: '0.3px 0.3px currentColor',
  },
  underline: {
    height: 3,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    transition: 'left 400ms ease-out, width 400ms ease-out, bottom 600ms ease-out',
    bottom: ({ activeLinkParams }: StyleProps) => activeLinkParams.bottom,
    left: ({ activeLinkParams }: StyleProps) => activeLinkParams.left,
    width: ({ activeLinkParams }: StyleProps) => activeLinkParams.width,
  },
}));

const DesktopMenu: FC<MenuProps> = ({ isTop, scrollDown }) => {
  const { asPath }: NextRouter = useRouter();
  const [activeLinkParams, setActiveLinkParams] = useState({
    width: 0,
    left: 0,
    bottom: 500,
  });
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles({ isTop, scrollDown, activeLinkParams });
  const bgColor = isTop ? 'transparent' : 'secondary';

  const NavLink: FC<NavLinkProps> = ({ label, path }) => {
    const ref = useRef(null);

    const handlePositionChange = () => {
      if (ref.current && path === asPath) {
        const { width, left } = ref.current.getBoundingClientRect();
        if (activeLinkParams.left !== left) {
          setActiveLinkParams({ width, left, bottom: 15 });
        }
      }
    };

    useEffect(() => {
      handlePositionChange();
      const notInNavbar = !routes.some(({ path }) => path === asPath);
      if (notInNavbar && activeLinkParams.width !== 0) {
        setActiveLinkParams({ width: 0, bottom: 500, left: activeLinkParams.left });
      }
      window.addEventListener('resize', handlePositionChange);
      return () => {
        window.removeEventListener('resize', handlePositionChange);
      };
    }, [asPath]);

    return (
      <div ref={ref}>
        <Link href={path} passHref>
          <a>
            <Typography className={clsx(classes.link, asPath === path && classes.activeLink)}>{label}</Typography>
          </a>
        </Link>
      </div>
    );
  };

  return (
    <AppBar color={bgColor} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Container>
          <Grid container>
            <Grid md={3} item>
              <Box display="flex" alignItems="center" height="100%">
                <Link href={HOME_ROUTE} passHref>
                  <a>{isTop ? <WhiteNavbarLogo /> : <ColoredNavbarLogo />}</a>
                </Link>
              </Box>
            </Grid>
            <Grid item md={isLarge ? 6 : 7}>
              <Box display="flex" justifyContent="space-around" alignItems="center" height="100%">
                {routes.map(({ label, path }, index) => (
                  <NavLink key={index} label={label} path={path} />
                ))}
                <Box className={classes.underline} />
              </Box>
            </Grid>
            <Grid item md={isLarge ? 3 : 2}>
              <Box display="flex" justifyContent="flex-end">
                <Link href={QUOTE_ROUTE}>
                  <a>
                    <Button size="small" variant="contained" color="primary">
                      Get a Quote
                    </Button>
                  </a>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default DesktopMenu;

import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';
import CalendarIconSvg from './calendar.svg';
import GlobeSvg from './globe.svg';
import EmployeeSvg from './employee.svg';
import BoxSvg from './box.svg';
import FooterLogoSvg from './footer-logo.svg';
import ArrowRightSvg from './arrow-right.svg';
import ArrowLineRightSvg from './arrow-line-right.svg';
import LinkedinSvg from './linkedin.svg';
import FacebookSvg from './facebook.svg';
import TwitterSvg from './twitter.svg';
import WhiteNavbarLogoSvg from './white-navbar-logo.svg';
import ColoredNavbarLogoSvg from './colored-navbar-logo.svg';
import MobileLogoColoredSvg from './mobile-logo-colored.svg';
import MobileLogoWhiteSvg from './mobile-logo-white.svg';
import BurgerDarkSvg from './burger-dark.svg';
import BurgerLightSvg from './burger-light.svg';
import MobileMenuLogoSvg from './mobile-menu-logo.svg';
import FooterLogoSmSvg from './footer-logo-sm.svg';
import TruckSvg from './truck.svg';
import MiniTruckSvg from './mini-truck.svg';
import IntermodalSvg from './intermodal.svg';
import ArrowDownSvg from './arrow-down.svg';
import HandshakeSvg from './hand-shake.svg';
import ExpertSvg from './expert.svg';
import TruckInfoSvg from './truck-info.svg';
import EmailContactSvg from './email-contact.svg';
import LocationContactSvg from './location-contact.svg';
import PhoneContactSvg from './phone-contact.svg';
import FacebookContactSvg from './fb.svg';
import TwitterContactSvg from './twitter-contact.svg';
import LinkedinContactSvg from './linked-in.svg';
import PrimaryFacebookSvg from './color-fb.svg';
import PrimaryTwitterSvg from './color-twitter.svg';
import PrimaryLinkedInSvg from './color-linked.svg';
import BoxFindSvg from './box-find.svg';
import GraphLineUpSvg from './graph-line-up.svg';
import HandDollarSvg from './hand-dollar.svg';
import PersonAttachedSvg from './person-attached.svg';
import BlueCheckSvg from './blue-check.svg';
import BgMainImageSvg from './bg-main.svg';
import QuoteCarSvg from './quote-car.svg';
import QuoteContainerSvg from './quote-container.svg';
import QuoteTruckSvg from './quote-truck.svg';
import QuoteCarWhiteSvg from './quote-car-white.svg';
import QuoteContainerWhiteSvg from './quote-container-white.svg';
import QuoteTruckWhiteSvg from './quote-truck-white.svg';
import QuoteFormBgSvg from './quote-form-bg.svg';
import CalendarTimeSvg from './calendar-time.svg';
import InfoListSvg from './info-list.svg';
import LocationSvg from './location.svg';
import MoneySvg from './money.svg';
import TruckInfoWhiteSvg from './truck-info-white.svg';
import LogoAboutSvg from './logo-about.svg';
import CalendarInputSvg from './calendar-input.svg';

export const CalendarIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CalendarIconSvg ref={ref} {...props} />);
export const EmployeeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EmployeeSvg ref={ref} {...props} />);
export const GlobeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <GlobeSvg ref={ref} {...props} />);
export const BoxIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BoxSvg ref={ref} {...props} />);
export const FooterLogo: FC<SvgIconProps> = forwardRef((props, ref) => <FooterLogoSvg ref={ref} {...props} />);
export const ArrowRightIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowRightSvg ref={ref} {...props} />);
export const ArrowLineRightIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowLineRightSvg ref={ref} {...props} />);
export const LinkedinIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LinkedinSvg ref={ref} {...props} />);
export const FacebookIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FacebookSvg ref={ref} {...props} />);
export const TwitterIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TwitterSvg ref={ref} {...props} />);
export const WhiteNavbarLogo: FC<SvgIconProps> = forwardRef((props, ref) => <WhiteNavbarLogoSvg ref={ref} {...props} />);
export const ColoredNavbarLogo: FC<SvgIconProps> = forwardRef((props, ref) => <ColoredNavbarLogoSvg ref={ref} {...props} />);
export const MobileLogoColored: FC<SvgIconProps> = forwardRef((props, ref) => <MobileLogoColoredSvg ref={ref} {...props} />);
export const MobileLogoWhite: FC<SvgIconProps> = forwardRef((props, ref) => <MobileLogoWhiteSvg ref={ref} {...props} />);
export const BurgerDarkIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BurgerDarkSvg ref={ref} {...props} />);
export const BurgerLightIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BurgerLightSvg ref={ref} {...props} />);
export const MobileMenuLogoIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MobileMenuLogoSvg ref={ref} {...props} />);
export const FooterLogoSmIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FooterLogoSmSvg ref={ref} {...props} />);
export const TruckIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TruckSvg ref={ref} {...props} />);
export const MiniTruckIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MiniTruckSvg ref={ref} {...props} />);
export const IntermodalIcon: FC<SvgIconProps> = forwardRef((props, ref) => <IntermodalSvg ref={ref} {...props} />);
export const ArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowDownSvg ref={ref} {...props} />);
export const HandshakeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <HandshakeSvg ref={ref} {...props} />);
export const ExpertIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ExpertSvg ref={ref} {...props} />);
export const TruckInfoIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TruckInfoSvg ref={ref} {...props} />);
export const EmailContactIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EmailContactSvg ref={ref} {...props} />);
export const LocationContactIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LocationContactSvg ref={ref} {...props} />);
export const PhoneContactIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PhoneContactSvg ref={ref} {...props} />);
export const FacebookContactIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FacebookContactSvg ref={ref} {...props} />);
export const TwitterContactIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TwitterContactSvg ref={ref} {...props} />);
export const LinkedinContactIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LinkedinContactSvg ref={ref} {...props} />);
export const PrimaryFacebookIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PrimaryFacebookSvg ref={ref} {...props} />);
export const PrimaryTwitterIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PrimaryTwitterSvg ref={ref} {...props} />);
export const PrimaryLinkedInIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PrimaryLinkedInSvg ref={ref} {...props} />);
export const BoxFindIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BoxFindSvg ref={ref} {...props} />);
export const GraphLineUpIcon: FC<SvgIconProps> = forwardRef((props, ref) => <GraphLineUpSvg ref={ref} {...props} />);
export const HandDollarIcon: FC<SvgIconProps> = forwardRef((props, ref) => <HandDollarSvg ref={ref} {...props} />);
export const PersonAttachedIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PersonAttachedSvg ref={ref} {...props} />);
export const BlueCheckIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BlueCheckSvg ref={ref} {...props} />);
export const BgMainImage: FC<SvgIconProps> = forwardRef((props, ref) => <BgMainImageSvg ref={ref} {...props} />);
export const QuoteContainerIcon: FC<SvgIconProps> = forwardRef((props, ref) => <QuoteContainerSvg ref={ref} {...props} />);
export const QuoteTruckIcon: FC<SvgIconProps> = forwardRef((props, ref) => <QuoteTruckSvg ref={ref} {...props} />);
export const QuoteCarIcon: FC<SvgIconProps> = forwardRef((props, ref) => <QuoteCarSvg ref={ref} {...props} />);
export const QuoteContainerWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <QuoteContainerWhiteSvg ref={ref} {...props} />
));
export const QuoteTruckWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <QuoteTruckWhiteSvg ref={ref} {...props} />);
export const QuoteCarWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <QuoteCarWhiteSvg ref={ref} {...props} />);
export const QuoteFormBg: FC<SvgIconProps> = forwardRef((props, ref) => <QuoteFormBgSvg ref={ref} {...props} />);
export const CalendarTimeImage: FC<SvgIconProps> = forwardRef((props, ref) => <CalendarTimeSvg ref={ref} {...props} />);
export const InfoListImage: FC<SvgIconProps> = forwardRef((props, ref) => <InfoListSvg ref={ref} {...props} />);
export const LocationImage: FC<SvgIconProps> = forwardRef((props, ref) => <LocationSvg ref={ref} {...props} />);
export const MoneyImage: FC<SvgIconProps> = forwardRef((props, ref) => <MoneySvg ref={ref} {...props} />);
export const TruckInfoWhiteImage: FC<SvgIconProps> = forwardRef((props, ref) => <TruckInfoWhiteSvg ref={ref} {...props} />);
export const LogoAboutIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LogoAboutSvg ref={ref} {...props} />);
export const CalendarInputIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CalendarInputSvg ref={ref} {...props} />);

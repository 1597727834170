import React, { FC } from 'react';
import { Box, Container, Grid, List, ListItem, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { FacebookIcon, FooterLogo, FooterLogoSmIcon, LinkedinIcon } from 'svgs';
import Link from 'next/link';
import { CARRIERS_ROUTE, CONTACT_US_ROUTE, COOKIE, PRIVACY, QUOTE_ROUTE, TERMS } from 'data/routes';
import { FACEBOOK_LINK, LINKEDIN_LINK } from 'data/constants';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
    background: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  list: {
    color: 'white',
    '& h6': {
      marginBottom: theme.spacing(3),
    },
  },
  bottom: {
    padding: theme.spacing(9, 0, 3),
  },
  textInput: {
    padding: theme.spacing(2, 3),
    fontSize: 18,
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      color: '#B4BBC6',
    },
  },
  submitButton: {
    width: 50,
    height: 45,
    padding: 0,
  },
  grid: {
    marginTop: theme.spacing(9),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(0),
    },
  },
  subtitle: {
    color: 'white',
    marginTop: theme.spacing(2),
    fontWeight: 400,
  },
}));

const Footer: FC = () => {
  const classes = useStyles();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <footer className={classes.footer}>
      <Container fixed maxWidth={false} className={classes.container}>
        <Grid container>
          <Grid lg={6} md={6} sm={6} xs={12} item className={classes.grid}>
            <div data-aos="fade-up" data-aos-delay={isSmall ? 0 : 100}>
              {isSmall ? <FooterLogoSmIcon /> : <FooterLogo />}
              <Typography className={classes.subtitle}>Moving the right way</Typography>
              <Box display="flex" mt={5}>
                <a href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </a>
                <Box mr={8} />
                <a href={LINKEDIN_LINK} target="_blank" rel="noopener noreferrer">
                  <LinkedinIcon />
                </a>
              </Box>
            </div>
          </Grid>

          <Grid lg={2} md={6} sm={6} xs={12} item className={classes.grid}>
            <div data-aos="fade-up" data-aos-delay={isSmall ? 0 : 300}>
              <List className={classes.list}>
                <ListItem disableGutters>
                  <Typography variant="subtitle1">
                    <span>About</span>
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <Link href={COOKIE}>
                    <a>
                      <Typography>Cookie policy</Typography>
                    </a>
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link href={PRIVACY}>
                    <a>
                      <Typography>Privacy policy</Typography>
                    </a>
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link href={TERMS}>
                    <a>
                      <Typography>Terms of use</Typography>
                    </a>
                  </Link>
                </ListItem>
              </List>
            </div>
          </Grid>

          <Grid lg={2} md={6} sm={6} xs={12} item className={classes.grid}>
            <div data-aos="fade-up" data-aos-delay={isSmall ? 0 : 400}>
              <List className={classes.list}>
                <ListItem disableGutters>
                  <Typography variant="subtitle1">
                    <span>Useful Links</span>
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <Link href={QUOTE_ROUTE}>
                    <a>
                      <Typography>Request a Quote</Typography>
                    </a>
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link href={CARRIERS_ROUTE}>
                    <a>
                      <Typography>Become a Carrier</Typography>
                    </a>
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link href={CONTACT_US_ROUTE}>
                    <a>
                      <Typography>Get in Touch</Typography>
                    </a>
                  </Link>
                </ListItem>
              </List>
            </div>
          </Grid>

          <Grid lg={2} md={6} sm={6} xs={12} item className={classes.grid}>
            <div data-aos="fade-up" data-aos-delay={isSmall ? 0 : 500}>
              <List className={classes.list}>
                <ListItem disableGutters>
                  <Typography variant="subtitle1">
                    <span>Our Contacts</span>
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <a href="mailto:info@patrontrucking.com">
                    <Typography>info@patrontrucking.com</Typography>
                  </a>
                </ListItem>
                <ListItem disableGutters>
                  <a href="tel:+1 (425) 999-9987">
                    <Typography>+1 (425) 999-9987</Typography>
                  </a>
                </ListItem>
                <ListItem disableGutters>
                  <a href="https://goo.gl/maps/ko5mH2KsfTxsCcJA9" target="_blank" rel="noopener noreferrer">
                    <Typography>
                      1051 Perimeter Dr, <br />
                      Suite 510, Schaumburg, <br /> IL 60173
                    </Typography>
                  </a>
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.bottom} textAlign="center">
        <Typography color="secondary">&copy; {new Date().getFullYear()} Patron Trucking. All Right Reserved.</Typography>
      </Box>
    </footer>
  );
};

export default Footer;

import * as d3 from 'd3-ease';

export const animationTitle = {
  from: { opacity: 0, transform: 'translate3d(-60px, 40px, 0) scale(0.8)' },
  to: { opacity: 1, transform: 'translate3d(0, 0, 0) scale(1)' },
  config: {
    duration: 300,
    easing: (t: number): number => d3.easeQuadIn(t),
  },
};

export const animationText = (delay: number) => ({
  from: { opacity: 0, transform: 'translate3d(0px, 40px, 0)' },
  to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  config: {
    duration: 350,
    easing: (t) => d3.easeQuadOut(t),
  },
  delay,
});

export const hideAnimationTitle = {
  opacity: 0,
  transform: 'translate3d(-60px, 40px, 0) scale(0.8)',
  transition: 'transform 300ms ease-out, opacity 300ms ease-out',
};

export const showAnimationTitle = (delay: string) => ({
  opacity: 1,
  transform: 'translate3d(0, 0, 0) scale(1)',
  transition: 'transform 300ms ease-out, opacity 300ms ease-out',
  transitionDelay: delay,
});

export const hideAnimationText = {
  opacity: 0,
  transform: 'translate3d(0, 40px, 0)',
  transition: 'transform 300ms ease-out, opacity 300ms ease-out',
};

export const showAnimationText = (delay: string) => ({
  opacity: 1,
  transform: 'translate3d(0, 0, 0)',
  transition: 'transform 300ms ease-out, opacity 300ms ease-out',
  transitionDelay: delay,
});

import React, { FC } from 'react';
import Head from 'next/head';
import { BASE_URL } from 'config/env';

interface SEOProps {
  title: string;
  description?: string;
}

const SEO: FC<SEOProps> = ({ title, description = '' }) => {
  const metaImage = `${BASE_URL}/logo-bg.jpg`;
  const titleTemplate = `${title} | Patron Trucking`;
  return (
    <Head>
      <title>{titleTemplate}</title>
      <meta name="og:title" content={titleTemplate} />
      <meta name="twitter:title" content={titleTemplate} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="image" content={metaImage} />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:card" content="summary" />
      <meta name="og:type" content="website" />
    </Head>
  );
};

export default SEO;

import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Box, fade, FilledTextFieldProps, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isNumberRegax } from 'utils/regax';

interface TextInputProps extends Partial<FilledTextFieldProps> {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  label?: string;
  error?: boolean;
  hintMessage?: string;
  counter?: number;
  number?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    position: 'absolute',
    color: theme.palette.secondary.light,
    top: theme.spacing(1.75),
    left: theme.spacing(3),
    zIndex: 1,
    transition: 'all 200ms ease-out',
  },
  inputActive: {
    top: theme.spacing(-5),
    left: 0,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  hintMessage: {
    fontSize: 14,
    position: 'absolute',
    right: 0,
    top: theme.spacing(-5),
    color: theme.palette.text.primary,
    animation: '$hintText 350ms ease-out forwards',
  },
  hintError: {
    color: theme.palette.error.main,
    transition: 'all 200ms ease-in',
  },
  focused: {
    animation: '$focusInput 200ms ease-out forwards',
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  labelHovered: {
    backgroundColor: fade(theme.palette.common.black, 0.13),
  },
  '@keyframes focusInput': {
    from: {
      transform: `translateX(-15px)`,
    },
    to: {
      transform: 'translateX(0)',
    },
  },
  counter: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    transition: 'all 200ms ease-in',
  },
  '@keyframes hintText': {
    from: {
      transform: `translateY(15px)`,
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
}));

const TextInput: FC<TextInputProps> = ({
  onChange,
  counter,
  multiline,
  required,
  value,
  label,
  error,
  hintMessage,
  onFocus,
  onBlur,
  number,
  ...props
}) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState<boolean>(null);
  const [labelHover, setLabelHover] = useState<boolean>(false);
  const inputRef = useRef(null);
  const handleFocusLabel = () => inputRef && inputRef.current.focus();

  const handleFocus = (e) => {
    onFocus(e);
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    onBlur(e);
    setIsFocused(false);
  };

  const handleChange = (e) => {
    if (number) return isNumberRegax(e, onChange);
    onChange(e);
  };

  const placeholderActive = isFocused || (value && classes.inputActive);
  const isInputActive = isFocused && !value && classes.focused;
  const charCounter = value.trim().length || 0;
  return (
    <Box position="relative">
      {label && (
        <Typography
          onClick={handleFocusLabel}
          onMouseEnter={() => setLabelHover(true)}
          onMouseLeave={() => setLabelHover(false)}
          className={clsx(classes.label, placeholderActive && classes.inputActive, error && classes.errorColor)}
        >
          {label}{' '}
          {required && (
            <Typography color={error ? 'error' : 'primary'} className={clsx(error && classes.errorColor)} component="span">
              *
            </Typography>
          )}
        </Typography>
      )}
      {hintMessage && <Typography className={clsx(classes.hintMessage, error && classes.hintError)}>{hintMessage}</Typography>}

      <TextField
        {...props}
        fullWidth
        variant="filled"
        value={value}
        error={error}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        multiline={multiline}
        InputProps={{ disableUnderline: true, className: clsx(labelHover && classes.labelHovered) }}
        inputProps={{
          className: clsx(isInputActive),
          ref: inputRef,
        }}
      />

      {counter && (
        <Typography className={clsx(classes.counter, error && classes.errorColor)}>
          {charCounter} / {counter}
        </Typography>
      )}
    </Box>
  );
};

export default TextInput;

import React, { FC } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface CheckboxProps {
  onChange: (value: boolean) => void;
  className?: string;
  value: boolean;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainBox: {
    position: 'absolute',
    left: 0,
    top: '30%',
    width: 19,
    height: 19,
    backgroundColor: '#F4F7F8',
    boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      top: '50%',
      transform: 'translate(0, -50%)',
    },
  },
  checkbox: {
    backgroundColor: theme.palette.primary.main,
    width: 0,
    height: 0,
    opacity: 0.3,
    transition: 'all 200ms ease-out',
  },
  checked: {
    width: 11,
    height: 11,
    opacity: 1,
  },
  title: {
    paddingLeft: theme.spacing(5),
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  wrapper: {
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 5),
    },
  },
}));

const Checkbox: FC<CheckboxProps> = ({ value, onChange, className, title }) => {
  const classes = useStyles();
  const handleChange = () => onChange(!value);
  return (
    <Box className={clsx(classes.wrapper)} onClick={handleChange}>
      <Box className={clsx(classes.mainBox, className)}>
        <Box className={clsx(classes.checkbox, value && classes.checked)} />
      </Box>
      {title && <Typography className={classes.title}>{title}</Typography>}
    </Box>
  );
};

export default Checkbox;

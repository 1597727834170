export const HOME_ROUTE = '/';
export const SERVICES_ROUTE = '/services';
export const CARRIERS_ROUTE = '/carriers';
export const JOBS_ROUTE = '/jobs';
export const ABOUT_US_ROUTE = '/about-us';
export const CONTACT_US_ROUTE = '/contact-us';
export const QUOTE_ROUTE = '/quote';
export const COOKIE = '/cookie';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';

type TRoutePaths =
  | typeof HOME_ROUTE
  | typeof SERVICES_ROUTE
  | typeof CARRIERS_ROUTE
  | typeof ABOUT_US_ROUTE
  | typeof CONTACT_US_ROUTE
  | typeof JOBS_ROUTE;

interface IRoutes {
  label: string;
  path: TRoutePaths;
}

export const hideNavBgRoutes: string[] = [
  QUOTE_ROUTE,
  HOME_ROUTE,
  SERVICES_ROUTE,
  CARRIERS_ROUTE,
  JOBS_ROUTE,
  ABOUT_US_ROUTE,
  CONTACT_US_ROUTE,
];

export const routes: IRoutes[] = [
  {
    label: 'Home',
    path: HOME_ROUTE,
  },
  {
    label: 'Logistic Services',
    path: SERVICES_ROUTE,
  },
  {
    label: 'Carriers',
    path: CARRIERS_ROUTE,
  },
  {
    label: 'About Us',
    path: ABOUT_US_ROUTE,
  },
  {
    label: 'Contact Us',
    path: CONTACT_US_ROUTE,
  },
];
